<template>
  <div>
    <Modal class="pop-contact-pad" footer-hide v-model="showContact">
      <div class="main-contact">
        <div class="left">
          <div class="left-title">
            <span>Contact</span> Connexima
          </div>
          <div class="left-sub-title">
            We appreciate your interest in Connexima. Please fill out some information below, thanks!
          </div>
          <div class="left-sub-form">
            <div class="form-item">
              <div class="name">
                <label>Name<span>*</span></label>
                <input type="text" v-model="form.user_name" :class="{ 'input-error': userNameError }"/>
              </div>
            </div>
            <div class="form-item">
              <div class="name">
                <label>Email<span>*</span></label>
                <input type="text" v-model="form.email" :class="{ 'input-error': emailError }"/>
              </div>
            </div>
            <div class="form-item">
              <div class="name">
                <label>Company</label>
                <input type="text"  v-model="form.company_name"/>
              </div>
            </div>
            <div class="form-item">
              <div class="name">
                <label>Message<span>*</span></label>
                <textarea type="text" v-model="form.message" :class="{ 'input-error': messageError }"></textarea>
              </div>
            </div>
            <div class="btn" v-if="notSubmit" @click="submitForm()">
              Submit
            </div>
            <div class="btn btn-loading" v-else>
              Submitting...
            </div>
          </div>
          <div class="others">
            <div class="other d-flex">
              <div class="icon"></div>
              <div class="other-word">
                <div class="label">EMAIL</div>
                <div class="value">contact@connexima.com</div>
              </div>
            </div>
          </div>
        </div>
        <div class="map">
          <div class="vue-map-container">
            <l-map
              v-if="showMap"
              :zoom="zoom"
              :center="center"
              :options="mapOptions"
              style="height: 80%"
              @update:center="centerUpdate"
              @update:zoom="zoomUpdate"
              ref="lMap"
            >
              <l-tile-layer
                :url="url"
                :attribution="attribution"
              />
              <l-marker :lat-lng="[33.714441, -117.762129]" :icon="icon">
                <l-popup>
                  <div>
                    Connexima
                    <p v-show="showParagraph">
                      4790 Irvine Blvd, Suite 105-189<br/>
                      Irvine, CA 92620
                    </p>
                  </div>
                </l-popup>
              </l-marker>

            </l-map>
          </div>

        </div>
        <div class="right"></div>
      </div>
    </Modal>
  </div>
</template>

<script>
  import { latLng,icon } from "leaflet";
  import { LMap, LTileLayer, LMarker, LPopup, LTooltip } from "vue2-leaflet";
  import 'leaflet/dist/leaflet.css';
  import api from "@/api";
  const { _createContact } = api;

  export default {
    props: [],
    components: {
      LMap,
      LTileLayer,
      LMarker,
      LPopup,
      LTooltip
    },
    data() {
      return {
        showContact: false,
        infoWindowPos: null,
        infoWinOpen: false,
        currentMidx: null,
        infoOptions: {
          center:{lat: 36.1, lng: -118.3},
          content: '',
          //optional: offset infowindow so it visually sits nicely on top of our marker
          pixelOffset: {
            width: 0,
            height: -35
          }
        },
        form: {
          user_name: "",
          email: "",
          company_name: "",
          message: ""
        },

        loading: false,
        notSubmit: true,
        userNameError: false,
        emailError: false,
        messageError: false,
        zoom: 11,
        center: latLng(33.714441, -117.762129),
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution:'',
        currentZoom: 11.5,
        showParagraph: true,
        mapOptions: {
          zoomSnap: 0.5,
        },
        showMap: false,
        icon: icon({
          iconUrl: require("@/assets/company.png"),
          iconSize: [35, 35],
          iconAnchor: [16, 37]
        }),
      };
    },
    mounted() {
      this.$nextTick(() => {
        this.showMap = true;
        if (this.$refs.lMap && this.$refs.lMap.invalidateSize) {
          this.$refs.lMap.invalidateSize();
        }
      });
    },
    methods: {
      zoomUpdate(zoom) {
        this.currentZoom = zoom;
      },
      centerUpdate(center) {
        this.currentCenter = center;
      },
      showLongText() {
        this.showParagraph = !this.showParagraph;
      },
      innerClick() {
        alert("Click!");
      },
      submitForm() {
        this.notSubmit = false;
        this.userNameError = this.emailError = this.messageError = false;
        if(!this.form.user_name || this.form.user_name.trim().length === 0){
          this.userNameError = true;
          this.notSubmit = true;
          return;
        }
        if(!this.form.email || this.form.email.trim().length === 0){
          this.emailError = true;
          this.notSubmit = true;
          return;
        }
        if(!this.form.message || this.form.message.trim().length === 0){
          this.messageError = true;
          this.notSubmit = true;
          return;
        }
        _createContact({
          ...this.form
        })
          .then(response => {
            this.$Message.success({
              content: response.msg,
              duration: 10,
              closable: true
            });
            this.form = {
              user_name: "",
              email: "",
              company_name: "",
              message: ""
            };
          })
          .catch(({ message }) => {
            this.$Message.error(message);
          })
          .finally(() => {
            this.notSubmit = true;
          });
      }
    }
  };
</script>
<style lang="less" scoped>
  .d-flex{
    display: flex;
  }
  .just-content-between{
    justify-content: space-between;
  }
  .main-contact{
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    .left{
      width:450px;
      padding: 20px 20px 20px 20px;
      .left-title{
        font-size: 22px;
        font-weight: 600;
        span{
          color: #FF4835;
        }
      }
      .left-sub-title{
        font-size: 14px;
        margin: 12px 0;
        font-weight: 500;
      }
      .left-sub-form{
        .name-h{
          width: 48%;
        }
        .name{
          margin-bottom: 15px;
          label{
            span{
              color: #FF4835;
              font-size: 18px;
              margin-left: 3px;
              position: relative;
              top: 4px;
            }

          }
          input{
            height: 38px;
            width: 100%;
            border: 1px solid #E0E0E0;
            outline: none;
            padding-left: 10px;
            margin-top: 5px;
            border-radius: 10px;
          }
          .input-error{
            border: 1px solid #FF4835;
          }
          textarea{
            height: 100px;
            width: 100%;
            border: 1px solid #E0E0E0;
            outline: none;
            padding: 10px;
            margin-top: 5px;
            border-radius: 10px;
          }
        }
        .btn{
          background: #FF4835;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          height: 37px;
          font-size: 14px;
          cursor: pointer;
          border-radius: 10px;
          margin-top: 20px;
          &:hover{
            opacity: 0.9;
          }
        }
        .btn-loading{
          opacity: 0.8;
          cursor: none;
        }
      }
      .others{
        margin-top: 30px;
        .other{
          .other-word{
            .label{
              font-size: 13px;
              font-weight: 600;
            }
            .value{
              font-size: 13px;
              color: #FF4835;
              cursor: pointer;
            }
          }
        }
      }
    }
    .map{
      position: relative;
      right: 10px;
      margin: 50px auto 20px auto;
      top: 30px;
      width: 0;
      .vue-map-container{
        width: 240px !important;
        height: 100%;
      }
    }

    .right{
      background: #183A4A;
      width: 320px;
      /*height: 100%;*/
    }
  }
</style>

<style lang="less">
  .pop-contact-pad {
    .ivu-modal {
      width: 680px !important;
      .ivu-modal-body{
        padding: 0;
      }
    }
  }
  @media (max-width: 767px) {
    .pop-contact {
      .ivu-modal {
        width: 90% !important;
        margin: 0 auto;
      }
    }
  }
  @media sreen and (min-width: 769px), sreen and (max-width: 1025px) {
    .pop-contact {
      .ivu-modal {
        margin: 0 auto;
        /*width: 50px;*/
      }
    }
  }
</style>
<style lang="less" >
  .vue2leaflet-map .leaflet-control-attribution{
    display: none;
  }
</style>
