<template>
  <div class="main">
    <div class="top">
      <div class="top-content sc-width d-flex">
        <div class="top-left">
          <div class="top-left-title ">
            Maximize Your Global Sales Potential with <span>Our Sales Rep Outsource Center</span>
          </div>
          <div class="top-left-subtitle">
            Your central hub for global sales success.<br/>
            Linking you to targeted customers worldwide.<br/>
            Reaching new heights in global market penetration.
          </div>
          <div class="top-left-btn" @click="start">
            Start Now!
          </div>
        </div>
        <div class="top-right">
          <div class="top-right-1">
            <div class="top-right-item">
              Nutraceutical
            </div>
            <div class="top-right-item">
              Organic
            </div>
          </div>
          <div class="top-right-2">
            <div class="top-right-item">
              Food & Beverage
            </div>
            <div class="top-right-item">
              Cosmetic
            </div>
          </div>
          <div class="top-right-3">
            <div class="top-right-item">
              Pharma
            </div>
            <div class="top-right-item">
              Animal Nutrition
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="mid d-just-content-between sc-width">
      <div class="mid-item">
        <div class="item-icon icon">
          <img src="@/assets/max-sales/mid-1.png" alt=""/>
        </div>
        <div class="item-title word">100K+ Database</div>
        <div class="item-subtitle ">Our vast customer base.</div>
      </div>
      <div class="mid-item">
        <div class="item-icon icon">
          <img src="@/assets/max-sales/mid-2.png" alt=""/>
        </div>
        <div class="item-title word">Targeted Outreach</div>
        <div class="item-subtitle ">Connect with your ideal
          customers.</div>
      </div>
      <div class="mid-item">
        <div class="item-icon icon">
          <img src="@/assets/max-sales/mid-3.png" alt=""/>
        </div>
        <div class="item-title word">Direct Collaboration</div>
        <div class="item-subtitle ">Meetings with R&D, sourcing,
          buyers, and procurement.</div>
      </div>
      <div class="mid-item mid-item-margin">
        <div class="item-icon icon">
          <img src="@/assets/max-sales/mid-4.png" alt=""/>
        </div>
        <div class="item-title word">Strategic Consulting</div>
        <div class="item-subtitle ">Marketing, FDA,
          customs,3PL warehousing,
          and fulfillment.</div>
      </div>
      <div class="mid-item mid-item-margin">
        <div class="item-icon icon">
          <img src="@/assets/max-sales/mid-5.png" alt=""/>
        </div>
        <div class="item-title word">Inside Sales Reps</div>
        <div class="item-subtitle ">Dedicated, full-time
          professionals.</div>
      </div>
      <div class="mid-item mid-item-margin">
        <div class="item-icon icon">
          <img src="@/assets/max-sales/mid-6.png" alt=""/>
        </div>
        <div class="item-title word">Seamless
          Communication</div>
        <div class="item-subtitle ">Native language, local time
          zone.</div>
      </div>
    </div>

    <div class="bottom ">
      <div class="bottom-content d-just-content-between sc-width">
        <div class="bottom-left">
          <div class="bottom-left-title">
            Maximize Your Brand And Sales with <span>Our Robust Backend Data</span>
          </div>
          <div class="bottom-left-subtitle">
            Gain an edge with our expert team.<br/>
            Elevate your sales with dedicated professionals.<br/>
            Amplify your brand and increase product sales.
          </div>
        </div>
        <div class="bottom-right">
          <div class="bottom-right-item">
            <div class="item-title">Leads Generation </div>
<!--            <div class="item-sub-title">$500+$30/prospect</div>-->
            <div class="item-sub-content">Nurturing Potential Customers into Sales Opportunities</div>
          </div>
          <div class="bottom-right-item">
            <div class="item-title">Email Campaign</div>
<!--            <div class="item-sub-title">$1000+$10/contact</div>-->
            <div class="item-sub-content">Targeted teams: R&D, sourcing, buyer
              , procurement team.</div>
          </div>
          <div class="bottom-right-item bottom-right-item-margin">
            <div class="item-title">Virtual Assistant </div>
<!--            <div class="item-sub-title">$1000+$500/prospect</div>-->
            <div class="item-sub-content">Types: webinar, demo, trade show, visit.</div>
          </div>
          <div class="bottom-right-item bottom-right-item-margin">
            <div class="item-title">Inside Sales</div>
<!--            <div class="item-sub-title">$2000/month</div>-->
            <div class="item-sub-content">Minimum 12 months.</div>
          </div>
          <div class="bottom-right-item bottom-right-item-margin">
            <div class="item-title">Market Strategic Planning </div>
            <!--            <div class="item-sub-title">$2000/month</div>-->
            <div class="item-sub-content">Leveraging Market Insights for Competitive Strategies</div>
          </div>

        </div>
      </div>

    </div>
    <PopContact ref="popContact"></PopContact>
  </div>
</template>

<script>
  import PopContact from "@/components/popContact";
export default {
  name: "max-sales",
  computed: {},
  data() {
    return {

    };
  },
  created() {},
  components: {  PopContact},
  mounted(){
    let contact = this.$route.query.contact;
    if (contact === "true") {
      this.$nextTick(() => {
        this.$refs.popContact.showContact = true;
      });

    }
  },

  methods: {
    start(){
      this.$refs.popContact.showContact = true;
    }
  }
};
</script>

<style lang="less" scoped>
  .main{
    background: #fff;
    margin-top: 75px;
  }
  .d-flex{
  display: flex;
  }
  .d-just-content-between{
    display: flex;
    justify-content: space-between;
  }
  .top{
    background-image: url(https://connexima.s3-us-west-1.amazonaws.com/documents/4Fg9yjJ9Ha4K5zqCpgtSRf.png);
    background-repeat: no-repeat;
    background-size: cover;
    /*background: #120D21;*/
    .top-content{
      padding-top: 60px;
      padding-bottom: 60px;
      margin: 0 auto;
    }
    .top-left{
      width: 830px;
      .top-left-title{
        font-weight: 700;
        font-size: 40px;
        color: #fff;
        span{
          color: #FF4835;
          font-weight: 700;
          font-size: 40px;
          /*margin-left: 7px;*/
        }
      }
      .top-left-subtitle{
        font-size: 21px;
        color: #fff;
        margin-top: 30px;
        font-weight: 500;
      }
      .top-left-btn{
        color: #FF4835;
        font-weight: 600;
        font-size: 21px;
        margin-top: 30px;
        cursor: pointer;
        border-bottom: 2px solid #FF4835;
        padding-bottom: 2px;
        width: fit-content;
      }
    }
    .top-right{
      margin-left: 180px;
      .top-right-item{
        height: 70px;
        padding-left: 25px;
        padding-right: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        font-size: 21px;
        font-weight: 500;
        /*opacity: 0.75;*/
      }
      .top-right-1{
        display: flex;
        justify-content: flex-end;
        &>:first-child{
          background: #FAF5DF;
          color: #CA9B8C;
        }
        &>:last-child{
          background: #EADFF8;
          color: #B484D3;
          margin-left: 25px;
        }
      }
      .top-right-2{
        display: flex;
        margin-top: 30px;
        justify-content: flex-end;
        &>:first-child{
          background: #FCEDDF;
          color: #DCA291;
        }
        &>:last-child{
          background: #E8F1D9;
          color: #A4AF92;
          margin-left: 25px;
        }
      }
      .top-right-3{
        display: flex;
        margin-top: 30px;
        justify-content: flex-end;
        &>:first-child{
          background: #CDFFE4;
          color: #77CE9F;
        }
        &>:last-child{
          background: #E9EFFF;
          color: #B0BAD6;
          margin-left: 25px;
        }
      }
    }
  }

  .bottom{
    background: #120D21;
    .bottom-content{
      margin: 0 auto;
      padding-top: 80px ;
      padding-bottom: 60px ;
      .bottom-left{
        width: 580px;
        .bottom-left-title{
          font-weight: 700;
          font-size: 40px;
          color: #fff;
          span{
            font-weight: 700;
            font-size: 40px;
            color: #FF4835;
            /*margin-left: 4px;*/
          }
        }
        .bottom-left-subtitle{
          color: #fff;
          margin-top: 20px;
          font-size: 20px;
          font-weight: 500;
        }
      }
      .bottom-right{
        width: 870px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .bottom-right-item{
          width: 420px;
          height: 140px;
          background: #1B1430;
          border-radius: 15px;
          padding-left: 20px;
          padding-right: 20px;
          padding-top: 20px;
          .item-title{
            color: #FF4835;
            font-size: 20px;
            font-weight: 600;
          }
          .item-sub-title{
            color: #fff;
            margin-top: 8px;
            font-size: 17px;
            font-weight: 500;
          }
          .item-sub-content{
            color: #efefef;
            margin-top: 16px;
            font-size: 17px;
          }
        }
        .bottom-right-item-margin{
          margin-top: 25px;
        }
      }
    }
  }
  .mid{
    flex-wrap: wrap;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 40px;
    .mid-item{
      width: 500px;
      height: 280px;
      border-radius: 20px;
      padding: 45px 35px 0 35px;
      transition: background-color 0.2s;
      .item-icon{
        img{
          width: 35px;
          height: 35px;
        }
      }
      &:hover{
        .icon{
          filter: invert(100%);
        }
        .word{
          filter: invert(100%);
        }
        .item-subtitle{
          color: rgba(255, 255, 255, 0.85);
        }
      }
      &:nth-child(1){
        background: linear-gradient(130deg,#c3caf6,#ede0f9);
        &:hover{
          background-image: linear-gradient(130deg,#3f55dd,#9d4ee5);
        }
      }
      &:nth-child(2){
        background: linear-gradient(130deg,#e1f8ff,#cdffe4);
        &:hover{
          background-image: linear-gradient(130deg,#55b8d6,#47e48f)
        }
      }
      &:nth-child(3){
        background: linear-gradient(130deg,#ffe2df,#faf6e0);
        &:hover{
         background: linear-gradient(130deg,#e94231,#f1cc00)
        }
      }
      &:nth-child(4){
        background: linear-gradient(130deg,#e0fbfe,#ebf0f2);
        &:hover{
          background-image: linear-gradient(130deg,#6df0ff,#00a1e2)
        }
      }
      &:nth-child(5){
        background: linear-gradient(130deg,#edf8ed,#e7f8c3);
        &:hover{
          background-image: linear-gradient(130deg,#089499,#00ff84)
        }
      }
      &:nth-child(6){
        background: linear-gradient(130deg,#f7eefe,#fae9ec);
        &:hover{
          background-image:linear-gradient(130deg,#0c5bfa,#f66e7c 72%)
        }
      }
      .item-title{
        font-weight: 600;
        font-size: 22px;
        color: #000;
        margin-top: 20px;
      }

      .item-subtitle{
        font-size: 18px;
        margin-top: 30px;
        font-weight: 500;
        color: rgba(18, 13, 33, 0.75);
      }
    }
    .mid-item-margin{
      margin-top: 38px;
    }
  }
</style>
